import React from "react";
import parseNumberToTwoDigits from "../../utils/helpers/parseNumberToTwoDigits";

type DayButtonProps = {
  index: number;
  day: Date;
  selected?: boolean;
  onClick?: () => void;
};

function DayButton({index, day, selected, onClick}: DayButtonProps) {
  return (
    <div 
      className={`min-w-40 min-h-24 transition-colors duration-200 cursor-pointer ${selected ? "bg-blue-400 hover:bg-blue-500" : "bg-slate-400 hover:bg-slate-500"} text-white py-2`}
      onClick={onClick}
    >
      <p className='font-bold text-xl ml-2'>Dia</p>
      <p className='font-bold text-5xl text-center'>{parseNumberToTwoDigits(index)}</p>
      <p className='font-bold text-xl text-center'>{`${parseNumberToTwoDigits(day.getDay())}/${parseNumberToTwoDigits(day.getMonth())}`}</p>
    </div>
  );
}

export default DayButton;