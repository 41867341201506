import React from "react";
import LayoutContainer from "../components/LayoutContainer";
import LayoutsHeaderGate from "../components/Header/LayoutsHeaderGate";
import LayoutSection from "../components/LayoutSection";
import SponsorsContainer from "../components/SponsorsContainer";
import { useFetchDemoData } from "../hooks/useFetchDemoData";
import SectionHeader from "../components/SectionHeader";
import MultistepCheckout from "../components/MultistepCheckout";
import Loading from "./Loading";

function SubscriptionPayment() {
  const {data, loading} = useFetchDemoData();
  
  if (loading) {
    return (<Loading />);
  }

  return (
    <div className="subscriptionPayment">
      <LayoutContainer>
        <LayoutsHeaderGate />
        <LayoutSection transparent noBorder>
          <SectionHeader thin>Pagamento</SectionHeader>
          <MultistepCheckout subscriptionCategories={data?.subscriptioncategories!} />
        </LayoutSection>
        <LayoutSection>
          <SponsorsContainer
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
    </div>
  );
}

export default SubscriptionPayment;