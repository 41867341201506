import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"

import '../../../styles/speakerCarousel.css';
import { Participant } from "../../../types/EventData";
import ProfileBadge from "../../ProfileBadge";

type SpeakerCarouselData = {
  authors : Participant[]
}

function SpeakerCarousel({ authors } : SpeakerCarouselData) {
  return (
    <div className="speakerCarousel carousel">
      <Swiper
        modules={[Navigation, Pagination]}
        pagination={{clickable: true}}
        navigation={true}
      >
        {authors.map(author => (
          <SwiperSlide className="text-center">
            <ProfileBadge profile={author} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default SpeakerCarousel;