import React from "react";

type StepCircleProps = {
  index: number;
  title: string;
  fill?: boolean;
}

function StepCircle({index, title, fill}: StepCircleProps) {
  return (
    <div className="stepCircle">
      <div className="flex items-center gap-4 flex-col xl:flex-row">
        <div className={`${!fill ? 'bg-blue-800' : 'min-w-10 bg-transparent border border-2 border-blue-800'} w-10 h-10 rounded-full flex justify-center items-center`}>
          <p className={`${!fill ? 'text-white' : 'text-blue-800'} text-xl font-semibold`}>{index}</p>
        </div>
        <p className="text-lg font-semibold text-blue-800">{title.toUpperCase()}</p>
      </div>
    </div>
  );
}

export default StepCircle;