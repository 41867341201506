import React from 'react'
import LayoutContainer from '../components/LayoutContainer';
import { useFetchDemoData } from '../hooks/useFetchDemoData';
import LayoutsHeaderGate from '../components/Header/LayoutsHeaderGate';
import LayoutSection from '../components/LayoutSection';
import SectionHeader from '../components/SectionHeader';
import LayoutSectionGroup from '../components/LayoutSectionGroup';
import SponsorsContainer from '../components/SponsorsContainer';
import { useNavigate, useParams } from 'react-router-dom';
import convertDateToString from '../utils/helpers/convertDateToString';
import isTodayOrEarlier from '../utils/helpers/isDateTodayOrEalier';
import SubscriptionCategoriesTables from '../components/SubscriptionCategoriesTables';
import Loading from "./Loading";

const subscriptionscategory = [
  {
    title: 'Medico sócio SNB',
    posvalue: 180,
    prevalue: 150,
    quantity: 1
  },
  {
    title: 'Médico sócio ABN',
    posvalue: 120,
    prevalue: 90,
    quantity: 1
  },
  {
    title: 'Médico não sócio',
    posvalue: 210,
    prevalue: 180,
    quantity: 1
  },
  {
    title: 'Residente',
    posvalue: 120,
    prevalue: 90,
    quantity: 1
  },
  {
    title: 'Estudante de Graduação',
    posvalue: 120,
    prevalue: 90,
    quantity: 1
  },
  {
    title: 'Outros Profissionais',
    posvalue: 180,
    prevalue: 150,
    quantity: 1
  },
]

function Subscriptions() {
  const {data, loading} = useFetchDemoData();
  const { eventSlug } = useParams();
  const navigate = useNavigate();
  
  if (loading) {
    return (<Loading />);
  }

  return (
    <div className="Subscriptions layout">
      <LayoutContainer>
        <LayoutsHeaderGate />
        <LayoutSection>
          <SectionHeader thin>Inscrição</SectionHeader>
          <LayoutSectionGroup>
            <p className='mt-16'>As inscrições poderão ser feitas pelo site até o dia <span className="text-blue-800 font-bold">{convertDateToString(data?.paymentdate!)}</span></p>
          </LayoutSectionGroup>
          <LayoutSectionGroup>
            <p className='font-bold'>Formas de Pagamento</p>
            <p className='text-lg pt-2 pb-1'>A taxa inscrição deverá ser paga da seguinte forma:</p>
            <ul className='ms-8 flex flex-col gap-1 list-disc'>
              <li>Cartão de Crédio em parcela única</li>
              <li>Cartão de Débito</li>
              <li>PIX ou Boleto</li>
            </ul>
            <p className='pt-6'>Logo após clicar em "Inscreva-se", insira todos os dados requisitados e prossiga com o pagamento, assim que concluido, serão enviados ao seu e-mail todas as instruções para prosseguir e o QR Code para ser utilizado no dia do evento.</p>
            <p className='pt-2'>Em casos de dúvidas, entre em contato através do e-mail &lt;emaildefinido@peloadmin.com.br&gt; ou na nossa area de suporte <a className='underline text-blue-600' href={`/${eventSlug}/support`}>"Fale Conosco"</a></p>
          </LayoutSectionGroup>
          <LayoutSectionGroup>
            <p className='font-bold'>Categorias de Inscrição</p>
            <SubscriptionCategoriesTables 
              paymentdate={data?.paymentdate!}
              subscriptionCategories={data?.subscriptioncategories!}              
            />
          </LayoutSectionGroup>
        </LayoutSection>
        <button onClick={() => navigate(`/${eventSlug}/subscriptions/payment`)} className='bg-blue-400 cursor-pointer hover:bg-blue-500 self-center text-white text-lg rounded-lg max-w-48 text-center px-4 py-2 my-8 mb-16 transition-all'>Inscreva-se</button>
        <LayoutSection>
          <SponsorsContainer 
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
    </div>
  )
}

export default Subscriptions;