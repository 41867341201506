import React from "react";

type TextContainerProps = {
  title: string;
  titleCenter?: boolean;
  content: string[];
  authorName?: string;
}

function TextContainer({title, content, authorName, titleCenter}: TextContainerProps) {
  return (
    <div className="textContainer">
      <div className="w-full flex flex-col">
        <h2 className={`font-bold ${titleCenter ? 'text-center md:text-2xl mb-6' : 'text-lg mb-2'} ml-3`}>{title}</h2>
        <div>
          {content.map((phrase: string) => (
            <p className='mb-6 md:text-base sm:text-sm'>
              {phrase}
            </p>
          ))}
        </div>
        {authorName && (
          <h3 className="font-bold text-md self-end">{authorName}</h3>
        )}
      </div>
    </div>
  )
}

export default TextContainer;