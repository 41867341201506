import React from "react";

type SponsorItemProps = {
  pic?: string;
  alt?: string;
}

function SponsorItem({pic, alt}: SponsorItemProps) {
  return (
    <div className="sponsorItem">
      <img src={pic} alt={alt} />
    </div>
  );
}

export default SponsorItem;