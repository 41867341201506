import React from "react";

import { RiPencilFill } from "react-icons/ri";
import { GoPersonFill } from "react-icons/go";
import { FaPeopleGroup } from "react-icons/fa6";
import { SlCalender } from "react-icons/sl";
import IconCircle, { IconCircleColors } from "./IconCircle";
import LinkText from "./LinkText";
import LinkItem from "./LinkItem";
import ColumnList from "./ColumnList";
import RowList from "./RowList";

type QuickLinksProps = {
  horizontal?: boolean;
}

function QuickLinks({horizontal}: QuickLinksProps) {
  const QuickLinkItems = () => (
    <>
      <LinkItem href="/neurobahia/subscription" icon={RiPencilFill} color={IconCircleColors.Orange} content="FAÇA SUA INSCRIÇÃO" />
      <LinkItem href="/neurobahia/guests" icon={GoPersonFill} color={IconCircleColors.LightOrange} content="CONFIRA OS PALESTRANTES CONVIDADOS" />
      <LinkItem href="/neurobahia/comissions" icon={FaPeopleGroup} color={IconCircleColors.Blue} content="CONHEÇA OS MEMBROS DA COMISSÃO" />
      <LinkItem href="/neurobahia/schedule" icon={SlCalender} color={IconCircleColors.LightBlue} content="VERIFIQUE A AGENDA DO EVENTO" />
    </>
  )

  return (
    <div className="quickLink">
      {(horizontal ? (
        <RowList>
          <QuickLinkItems />
        </RowList>
      ) : (
        <ColumnList>
          <QuickLinkItems />
        </ColumnList>
      ))}
    </div>
  );
}

export default QuickLinks;