import React from "react";

interface LinkTextProps extends React.HTMLAttributes<HTMLLIElement> {
  children?: React.ReactNode;
}

function LinkText({children} : LinkTextProps) {
  return (
    <p className="w-full font-semibold">{children}</p>
  );
}

export default LinkText;