import React, { useState } from 'react'
import LayoutContainer from '../components/LayoutContainer';
import { useFetchDemoData } from '../hooks/useFetchDemoData';
import LayoutSection from '../components/LayoutSection';
import SectionHeader from '../components/SectionHeader';
import SponsorsContainer from '../components/SponsorsContainer';
import {ScheduleDay} from '../types/EventData';
import DayButton from '../components/DayButton';
import Divider from '../utils/ui/Divider';
import DayContainer from '../components/DayContainer';
import LayoutsHeaderGate from '../components/Header/LayoutsHeaderGate';
import Loading from "./Loading";

function Schedule() {
  const {data, loading} = useFetchDemoData();
  const [selectedDay, setSelectedDay] = useState<number | undefined>(undefined);

  if (loading) {
    return (<Loading />);
  }

  const scheduleData = data?.schedule;

  return (
    <div className="Schedule layout">
      <LayoutContainer>
        <LayoutsHeaderGate />
        <LayoutSection>
          <SectionHeader>PROGRAMAÇÃO</SectionHeader>
          <div className='w-full flex justify-center gap-4'>
            {scheduleData?.days.map((day: ScheduleDay, index) => {
              const currentIndex = index;
              return (
                <DayButton
                  selected={selectedDay == (currentIndex)}
                  day={day.date}
                  index={currentIndex + 1}
                  onClick={() => {
                    if ( selectedDay == currentIndex) {
                      return setSelectedDay(undefined);
                    }
                    return setSelectedDay(currentIndex);
                  }}
                />
              ); 
            })}
          </div>
          <Divider />
          
          {selectedDay == undefined ?
            (
              <div>
                <p className='text-center text-xl'>Selecione um dia para visualizar a programção</p>
              </div>
            ) :
            (
              <>
                {data?.schedule.days.map((day, index) => {
                  if (selectedDay != index) {
                    return (<></>);
                  }
                  return (
                    <DayContainer 
                      day={day}
                    />
                  );
                })}
              </>
            )
          }
        </LayoutSection>
        <LayoutSection>
          <SponsorsContainer 
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
    </div>
  )
}

export default Schedule;