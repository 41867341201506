import React from "react";
import { useSelector, useDispatch } from "react-redux";

import EventBanner from "../../components/Introduction/EventBanner";
import IntroductionSection from "../../components/Introduction/IntroductionSection";
import LayoutSection from "../../components/LayoutSection";
import SpeakerCarousel from "../../components/Speakers/SpeakerCarousel";
import QuickLinks from "../../components/QuickLinks";
import SectionHeader from "../../components/SectionHeader";
import LocationDetails from "../../components/Location/LocationDetails";
import LocationAddress from "../../components/Location/LocationAddress";
import LocationMap from "../../components/Location/LocationMap";
import SponsorsContainer from "../../components/SponsorsContainer";
import LayoutContainer from "../../components/LayoutContainer";
import Navbar from "../../components/Header/Navbar";
import { useFetchDemoData } from "../../hooks/useFetchDemoData";
import Loading from "../Loading";

type Layout1Props = {
  eventSlug: string;
}

function Layout1({eventSlug}: Layout1Props) {
  const {data, loading} = useFetchDemoData();

  if (loading) {
    return (<Loading />);
  }
  
  return (
    <div className="Layout1 layout">
        <LayoutContainer>
          <Navbar className="pb-2 pt-6" logo={data?.logourl!} layout={data?.layout!} />
          <LayoutSection noBorder>
            <EventBanner 
              banner={data?.banner!}
              eventDate={data?.startdate!}
              place={data?.place!}
            />
            <IntroductionSection
              title="Introducao"
              content={data!.introduction}
              author={data?.participants.find((participant) => participant.category?.name == "Presidente")!}
            />
          </LayoutSection>
          <LayoutSection>
            <SectionHeader>Palestrantes</SectionHeader>
            <div className="flex justify-center items-center gap-12 py-8">
              <div className="w-full">
                <SpeakerCarousel
                  authors={data?.participants.filter((participant) => participant.isspeaker)!}
                />
              </div>
              <div className="w-1/2">
                <QuickLinks />
              </div>
            </div>
          </LayoutSection>
          <LayoutSection>
            <SectionHeader>Localização</SectionHeader>
            <LocationDetails name={data?.place.name!} description={data?.place.description!}  />
            <LocationMap className="flex justify-center my-12"  location={data?.place!} />
            <LocationAddress
              street={data?.place.street!}
              number={data?.place.number!}
              district={data?.place.district!}
              city={data?.place.city!}
              state={data?.place.state!}
              telephone={data?.place.telephone!}
            />
          </LayoutSection>
          <LayoutSection>
            <SponsorsContainer 
              sponsorCategories={data?.sponsors!}
            />
          </LayoutSection>
        </LayoutContainer>
    </div>
  );
}

export default Layout1;