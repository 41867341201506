import React from "react";
import { motion } from 'framer-motion';
import Wave from "react-wavify";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import PageWaves from "../components/PageWaves";
import CredentechNavbar from "../components/CredentechNavbar";

import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"

function About() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="About"
    >
      <div className="flex flex-col items-start min-h-screen">
        <CredentechNavbar />
        <div className="flex-1 w-full flex flex-col lg:flex-row items-center px-2 lg:px-12 gap-8">
          <div className="flex flex-col justify-center w-full mt-12 lg:m-0 lg:w-1/2 text-center lg:text-left">
            <h1
              className="font-black text-5xl min-[2000px]:text-7xl"
              style={{
              color: '#FF6B00'
              }}
            >
              Sobre nós
            </h1>
            <p className="tracking-wide mt-8 text-lg">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris finibus  eleifend metus aliquam egestas. Sed lacinia ac magna luctus  pellentesque. Fusce finibus tellus non euismod mattis. Integer at  accumsan enim. Donec blandit arcu id sem ullamcorper bibendum. Proin  elementum ultricies purus, non rutrum quam pharetra tristique. Nam  dictum iaculis molestie. Fusce aliquam ut sem quis commodo. Mauris  mattis dictum convallis. Integer elementum venenatis quam, nec venenatis  nisl gravida nec. Morbi ullamcorper felis nec volutpat pharetra.
            </p>
            <button
              className="uppercase text-white bg-gradient-to-r from-orange-600 to-orange-800 px-12 py-3 rounded-full font-semibold transition-all ease-in-out hover:animate-gradient mt-8 max-w-64 m-auto lg:m-0"
              style={{
                backgroundSize: "200% 200%",
                backgroundPosition: "0% 50%",
                animation: "gradient-slide 3s ease infinite"
              }}
              >
              Fale Conosco!
            </button>
          </div>
          <div className="w-full lg:w-1/2">
            <Swiper
              modules={[Navigation, Pagination]}
              pagination={{clickable: true}}
              navigation={true}
            >
              <SwiperSlide>
                <img 
                  src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/banner.png"
                  className="px-24 py-8 h-[600px] object-cover object-center m-auto"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img 
                  src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/banner.png"
                  className="px-24 py-8 h-[600px] object-cover object-center m-auto"
                />
              </SwiperSlide>
              <SwiperSlide>
                <img 
                  src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/banner.png"
                  className="px-24 py-8 h-[600px] object-cover object-center m-auto"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
  
export default About;