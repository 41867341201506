import React from 'react'

import ProfileBadge from '../../ProfileBadge';
import { Participant } from '../../../types/EventData';
import TextContainer from './TextContainer';

type IntroductionData = {
  title : string;
  content : string[];
  author : Participant
  authorLeft?: boolean;
  authorCenter?: boolean;
  [key: string] : any;
}

function IntroductionSection({title, content, author, authorCenter, authorLeft, ...props} : IntroductionData) {
  return (
    <div className="introductionSection">
      {authorCenter ? (
        <div className="introduction-section px-12 flex flex-col justify-center items-center gap-4">
          <ProfileBadge 
            profile={author}
          />
          <TextContainer 
            title={title} 
            titleCenter
            content={content} 
            authorName={authorLeft ? author.name : ''} 
          />
        </div>
      ) : (
       <div className="introduction-section px-12 py-28 flex justify-center items-center gap-12">
        {(authorLeft && (
          <ProfileBadge 
            profile={author}
          />))}
          <TextContainer 
            title={title} 
            content={content} 
            authorName={authorLeft ? author.name : ''} 
          />
          {(!authorLeft && (
          <ProfileBadge 
            profile={author}
          />))}
        </div>
      )}
    </div>
  );
}

export default IntroductionSection