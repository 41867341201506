import React from "react";

function Info() {
  return (
    <div className="info">
      <div className="px-16 py-8 flex gap-6">
        <div className="flex-1 flex flex-col gap-12">
          <div>
            <h2 
              className="text-4xl font-semibold"
            >
              Título do Evento
            </h2>
            <input 
              type="text" 
              name="" 
              id=""
              placeholder="INSIRA O TÍTULO DO EVENTO"
              className="w-full text-md shadow-lg rounded-full mt-2 px-8 font-semibold py-2 border"
            />
          </div>
          <div>
            <h2 
              className="text-4xl font-semibold"
            >
              Logo do Evento
            </h2>
            <input 
              type="text" 
              name="" 
              id=""
              placeholder="INSIRA O TÍTULO DO EVENTO"
              className="w-full text-md shadow-lg rounded-full mt-2 px-8 font-semibold py-2 border"
            />
          </div>
        </div>
        <div className="bg-blue-200 flex-1">

        </div>
        <div className="bg-blue-50 flex-1">
          
        </div>
      </div>
    </div>
  );
}

export default Info;