import { useState, useEffect, useRef } from "react";
import { EventData } from "../types/EventData";
import axios from "axios";
import parseResponseTypes from "../utils/helpers/parseResponseTypes";
import { useParams } from "react-router-dom";

// const apiUrl = 'http://15.228.242.51:3333';
const apiUrl = 'https://api.credentech.com.br';

export const useFetchDemoData = () => {
  const { eventSlug } = useParams();
  const dataRef = useRef<EventData | null>(null);
  const [data, setData] = useState<EventData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    console.log(`Event slug: ${eventSlug}`);
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/get_event/13`);
        const demoData = response.data;
        parseResponseTypes(demoData);

        demoData.paymentdate = new Date(2024, 8, 8);

        console.log(demoData)

        if (!dataRef.current) {
          dataRef.current = demoData;
        }

        setData(demoData);
      } catch (err) {
        console.log(err)
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    if (!dataRef.current) {
      console.log(dataRef.current)
      fetchData();
    }
  }, []);
  
  return { data, setData, loading, error };
}