import React, { useEffect, useState } from "react";

import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { useFetchDemoData } from "../../hooks/useFetchDemoData";

import Layout1 from "./Layout1";
import Layout2 from "./Layout2";
import Layout3 from "./Layout3";
import Layout4 from "./Layout4";
import Comissions from "../Comissions";
import Guests from "../Guests";
import Expositors from "../Expositors";
import Subscriptions from "../Subscriptions";
import Schedule from "../Schedule";
import Support from "../Support";
import Edit from "../Edit";
import Loading from "../Loading";
import Error from '../Error';
import SubscriptionPayment from "../SubscriptionPayment";
import axios from "axios";
import parseResponseTypes from "../../utils/helpers/parseResponseTypes";
import { EventData } from "../../types/EventData";

const routesComponentMap: Record<string, React.ComponentType<any>> = {
  support: Support,
  layout1: Layout1,
  layout2: Layout2,
  layout3: Layout3,
  layout4: Layout4
}

function Layout() {
  const { eventSlug } = useParams();

  const {data, loading, error} = useFetchDemoData();

  if (loading) {
    return (<Loading />);
  }

  if (error) {
    return (<>Error</>);
  }
  
  const LayoutComponent = routesComponentMap[data?.layout || 'layout1'];

  return (
    <Routes>
      <Route path="support" element={<Support />} />
      <Route path="subscriptions" element={<Subscriptions />} />
      <Route path="schedule" element={<Schedule />} />
      <Route path="guests" element={<Guests />} />
      <Route path="expositors" element={<Expositors />} />
      <Route path="comissions" element={<Comissions />} />
      <Route path="subscriptions/payment" element={<SubscriptionPayment />} />
      <Route path="/*" element={<LayoutComponent />} />
    </Routes>
  );
}

export default Layout;