import React, { useState } from "react";
import ProgressItem from "./ProgressItem";

const stepsInfo = [
  "Informações gerais",
  "Detalhes do local",
  "Gerenciamento de pessoas",
  "Comissões",
  "Convidados",
  "Programação",
  "Patrocinadores",
  "Configurações"
]

type EventManagerProgressProps = {
  currentStep?: number;
  stepChange?: (step: number) => void;
}

function EventManagerProgress({currentStep, stepChange}: EventManagerProgressProps) {
  return (
    <div className="eventManagerProgress">
      <ul className="text-lg font-bold flex justify-center gap-4">
        {stepsInfo.map((step, index) => (
          <ProgressItem delay={0.05 * (index + 1)} selected={currentStep == index}>
            <a 
              onClick={() => stepChange!(index)}
              className="cursor-pointer"
            >
              {step}
            </a>
          </ProgressItem>
        ))}
      </ul>
    </div>
  );
}

export default EventManagerProgress;