import React from "react";
import SponsorSection from "./SponsorSection";
import { SponsorCategory } from "../../types/EventData";
import SponsorItem from "./SponsorItem";

type SponsorsContainerProps = {
  sponsorCategories: SponsorCategory[];
  [key: string]: any;
}

function SponsorsContainer({sponsorCategories}: SponsorsContainerProps) {
  return (
    <div className="sponsorsContainer">
      <div className="px-48 py-2">
        {sponsorCategories.map(({name, participants}) => (
          <SponsorSection title={name}>
            <div className="py-8 px-32 flex justify-around">
              {participants.map((sponsor) => (
                <SponsorItem pic={sponsor.pic} alt={sponsor.name} />
              ))}
            </div>
          </SponsorSection>
        ))}
      </div>
    </div>
  );
}

export default SponsorsContainer;