import React from "react";
import Header from "../..";
import Navbar from "../../Navbar";
import { useFetchDemoData } from "../../../../hooks/useFetchDemoData";
import Loading from "../../../../pages/Loading";

type Layout3Header = {
  logo?: string;
  layout: "layout1" | "layout2" | "layout3" | "layout4"
}

function Layout3Header({logo, layout}: Layout3Header) {
  const {data, loading} = useFetchDemoData();
  
  if (loading) {
    return (<Loading />);
  }

  return (
    <div className="flex justify-between py-6">
      <Header logo={data?.logourl!} />
      <Navbar column logo={data?.logourl!} layout={data?.layout!} />
    </div>
  );
}

export default Layout3Header;