import React from "react";
import {useForm, SubmitHandler}  from "react-hook-form";
import { EventData } from "../types/EventData";
import { redirect, useNavigate } from "react-router-dom";
import { useFetchDemoData } from "../hooks/useFetchDemoData";

type FormData = {
  title: string;
  layout: "layout1" | "layout2" | "layout3" | "layout4";
  eventKey: string;
  introduction: string;
  startDate: Date;
  name: string;
  district: string;
  city: string;
  state: string;
  cep: string;
  number: string;
  complement: string;
  reference: string;
  telephone: string;
  street: string;
  description: string;
}

const inputStyle = "w-full text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 transition duration-300 shadow-sm hover:shadow-md";
const textareaStyle = `${inputStyle} resize-none h-32`;

const Input = ({ ...props }) => <input className={inputStyle} {...props} />;
const Textarea = ({ ...props }) => <textarea className={textareaStyle} {...props} />;

function Edit() {
  const {data, setData} = useFetchDemoData();
  const navigate = useNavigate();
  const {register, handleSubmit} = useForm<FormData>();
  
  const onSubmit: SubmitHandler<FormData> = (formData) => {
    const paragraphs = formData.introduction.trim().split("\n\n");
    console.log(paragraphs);
  };
  
  return (
    <div className="edit">
      <div className="min-h-screen flex justify-center items-center">
        {data ? (
          <form className="w-full max-w-2xl p-8" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col justify-center items-center w-full gap-4"> 
              <h2 className="font-bold text-2xl">Informações básicas</h2>
              <div className="text-center">
                <label className="text-xl" htmlFor="titleInput">Titulo</label>
                <Input 
                  id="titleInput" 
                  defaultValue={data.banner.title} 
                  {...register("title")} 
                />
              </div>
              <div className="text-center w-full">
                <label className="text-xl" htmlFor="startDateInput">Dia Inicial do Evento</label>
                <Input
                  id="startDateInput"
                  type="date"
                  defaultValue={data.startdate.toISOString().split('T')[0]} {...register("startDate")}
                />
              </div>
              <div className="text-center w-full">
                <label className="text-xl" htmlFor="introductionInput">Introdução</label>
                <Textarea
                  id="introductionInput"
                  defaultValue={data.introduction.join("\n\n")}
                  {...register("introduction")}
                />
              </div>
              <h2 className="font-bold text-2xl">Localização</h2>
              <label className="text-lg" htmlFor="placeNameInput">Nome de local</label>
              <Input
                id="placeNameInput"
                defaultValue={data.place.name} 
                {...register('name')}
              />
              <label className="text-lg" htmlFor="telephoneInput">Telefone do local</label>
              <Input 
                id="telephoneInput"
                defaultValue={data.place.telephone}
                placeholder="Telephone"
                {...register('telephone')}
              />
              <label className="text-lg" htmlFor="placeDescriptionInput">Descrição do local</label>
              <Textarea 
                id="placeDescriptionInput"
                defaultValue={data.place.description}
                placeholder="Description"
                {...register('description')}
              />
              <label className="text-lg">Endereço</label>
              <div className="grid grid-cols-2 gap-4">
                <Input 
                  defaultValue={data.place.district} 
                  {...register('district')} 
                />
                <Input 
                  defaultValue={data.place.city} 
                  {...register('city')}
                />
                <Input 
                  defaultValue={data.place.state} 
                  placeholder="State"
                  {...register('state')} 
                />
                <Input 
                  defaultValue={data.place.city} 
                  placeholder="CEP"
                  {...register('cep')} 
                />
                <Input 
                  defaultValue={data.place.street} 
                  placeholder="Street" 
                  {...register('street')}
                />
                <Input
                  defaultValue={data.place.number}
                  placeholder="Number" 
                  {...register('number')}
                />
                <Input
                  defaultValue={data.place.complement}
                  placeholder="Complement" 
                  {...register('complement')}
                />
              </div>
              <Input 
                defaultValue={data.place.reference}
                placeholder="Reference" 
                {...register('reference')} 
              />
              <div className="text-center">
                <label className="text-xl" htmlFor="layoutSelect">Escolha o Layout</label>
                <select
                  id="layoutSelect"
                  className="w-full text-sm text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 transition duration-300 shadow-sm"
                  {...register("layout")}
                >
                  <option value="layout1">Layout 1</option>
                  <option value="layout2">Layout 2</option>
                  <option value="layout3">Layout 3</option>
                  <option value="layout4">Layout 4</option>
                </select>
              </div>
              <input className="bg-emerald-600 text-white px-6 py-2 rounded-full cursor-pointer hover:bg-emerald-700 transition duration-300" value="Editar" type="submit" />
            </div>
          </form>
        ) : (
          <>loading...</>
        )}
      </div>
    </div>
  );
}

export default Edit;