import React from "react";

interface NavItemProps extends React.HTMLAttributes<HTMLLIElement> {
  children: React.ReactNode;
  column?: boolean;
  redirect?: string;
  className?: string;
  hasDropdown?: boolean;
}

function NavItem({children, redirect, hasDropdown, column, className, ...props} : NavItemProps) {
  const dropdownItems = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === NavItem) {
      return child;
    }
  });

  const contentItems = React.Children.map(children, (child) => {
    if (
      React.isValidElement(child) && child.type !== NavItem ||
      typeof child === "string" || typeof child === "number"
    ) {
      return child;
    }
    return null;
  });

  return (
    <li
      className={`${(redirect && "hover:cursor-pointer")}  z-10 navitem hover:bg-blue-600 bg-blue-500 transition-all text-white py-1 rounded-md w-full text-center uppercase relative ${className} ${
        hasDropdown ? 'group' : ''
      }`}
      {...props}
    >
      <a className="block w-full h-full" href={redirect}>{contentItems}</a>
      {hasDropdown && dropdownItems && (
        <ul
          className={`"dropdown-content absolute ${column ? 'top-0 right-full bg-blue-500 text-white' : 'left-0 top-full'} rounded-lg text-black w-full max-h-0 opacity-0 overflow-hidden group-hover:max-h-96 group-hover:opacity-100 transition-all duration-300 ease-in-out"`}
          style={(column ? {marginRight: '0.1rem'} : {})}
        >
          {dropdownItems}
        </ul>
      )}
    </li>
  );
}

export default NavItem;