import React from "react";
import isTodayOrEarlier from "../../utils/helpers/isDateTodayOrEalier";
import convertDateToString from "../../utils/helpers/convertDateToString";
import { SubscriptionCategory } from "../../types/EventData";

type SubscriptionCategoriesTablesProps = {
  paymentdate: Date;
  subscriptionCategories: SubscriptionCategory[];
}

function SubscriptionCategoriesTables({paymentdate, subscriptionCategories}: SubscriptionCategoriesTablesProps) {
  return (
    <table className='table-auto w-full block mt-5'>
      <thead className='w-full block'>
        <tr className='w-full flex gap-2'>
          <th className='bg-blue-500 font-semibold text-white py-2 px-4 w-2/3'>Categoria</th>
          <th className={`${!isTodayOrEarlier(paymentdate!) ? 'line-through' : ''} bg-blue-500 font-semibold text-white py-2 px-4 w-1/4`}>Até dia {convertDateToString(paymentdate!)}</th>
          <th className='bg-blue-500 font-semibold text-white py-2 px-4 w-1/4'>Após dia {convertDateToString(paymentdate!)}</th>
        </tr>
      </thead>
      <tbody className='w-full block'>
        {subscriptionCategories.map((subscriptionCategory, index) => {
          return (
            <tr className='w-full flex gap-2 text-center'>
              <td className={`font-normal font-medium py-2 px-4 w-2/3 ${index % 2 == 0 ? 'bg-slate-200' : 'bg-slate-300'}`}>{subscriptionCategory.title}</td>
              <td className={`${!isTodayOrEarlier(paymentdate!) ? 'line-through' : ''} font-normal font-medium py-2 px-4 w-1/4 ${index % 2 == 0 ? 'bg-slate-200' : 'bg-slate-300'}`}>R$ {subscriptionCategory.prevalue}</td>
              <td className={`font-normal font-medium py-2 px-4 w-1/4 ${index % 2 == 0 ? 'bg-slate-200' : 'bg-slate-300'}`}>R$ {subscriptionCategory.posvalue}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ); 
}

export default SubscriptionCategoriesTables;