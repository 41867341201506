import React from "react";

interface LayoutProps extends React.HTMLAttributes<HTMLLIElement> {
  children: React.ReactNode;
}

function LayoutContainer({children}: LayoutProps) {
  return (
    <div className="LayoutContainer flex flex-col justify-center sm:px-0 md:px-6 lg:px-24 xl:px-32 2xl:px-48">
      {children}
    </div>
  );
}

export default LayoutContainer;