import React from "react";

import formatDate from "../../../utils/helpers/formatDate";

import Dot from "../../../utils/ui/Dot";
import { Banner, Place } from "../../../types/EventData";

type EventBannerProps = {
  banner: Banner;
  eventDate: Date;
  place: Place;
  [key: string] : any;
}

function EventBanner({banner, place, eventDate, ...props} : EventBannerProps) {
  // const {title, backgroundImgs, date} = banner;
  const {title, backgroundimgs} = banner;
  const titleWords = title.split(' ');

  // const image: string | undefined = typeof backgroundImgs[0] === 'string' ? backgroundImgs[0] : undefined;
  return (
    <div className="eventBanner relative bg-gray-500 w-full h-96 overflow-hidden rounded-t-lg">
      <img src={backgroundimgs[0]!.src} alt={backgroundimgs[0]!.alt} className="w-full h-full object-cover" />
      <div className="absolute inset-0 bg-gradient-radial"></div>
      <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
        <h1 className="font-black sm:text-5xl 2xl:text-7xl max-w-xl text-center tracking-wider leading-tight uppercase">
          <span>{titleWords[0]}</span> <br />
          {titleWords[1]}
        </h1>
        <div className="flex 2xl:flex-row sm:flex-col sm:mt-12 justify-between items-center gap-4">
          <p className="font-bold lg:max-w-28 xl:max-w-32 text-center">{formatDate(eventDate)}</p>
          <Dot color="rgb(255,131,78)" />
          <p className="text-lg xl:px-0 2xl:px-2">{place.name}</p>
          <Dot color="rgb(255,131,78)" />
          <p className="text-lg xl:px-0 2xl:px-2">{place.city} - {place.state}</p>
        </div>
      </div>
    </div>
  );
}

export default EventBanner;