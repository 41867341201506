import React from 'react';
import Wave from 'react-wavify';

import RedirectButton from '../utils/ui/RedirectButton';
import { motion } from 'framer-motion';
import PageWaves from '../components/PageWaves';

function Home() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="Home"
    >
      <div className='flex min-h-screen'>
        <div className="waves w-1/2 relative flex flex-col justify-center items-center">
          <div className="content text-center">
            <p className='text-xl mb-4'>BEM VINDO A</p>
            <img 
              className='m-auto'
              src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/credentechlogo.png" 
              alt="Credentech" 
            />
            <p className='max-w-96 my-12 text-stone-500'>Clique para mais informações sobre nosso
            sistema de credenciamento de eventos</p>
            <a
              className="uppercase text-white bg-gradient-to-r from-orange-600 to-orange-800 px-12 py-3 rounded-full font-semibold transition-all ease-in-out hover:animate-gradient"
              href="/about"
              style={{
                backgroundSize: "200% 200%",
                backgroundPosition: "0% 50%",
                animation: "gradient-slide 3s ease infinite"
              }}
            >
              Leia Mais
            </a>
          </div>
          <PageWaves />
        </div>
        <div className="image w-1/2">
          <div 
            className="relative h-screen w-full border-l-4 border-orange-500 flex flex-col items-center justify-center"
            style={{
              backgroundImage: "url('https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/homebg.png')",
              backgroundSize: "cover",
              backgroundPosition: "center"
            }}
          >
            <img 
              className='z-10 relative'
              src="https://credentech-assets-test.s3.sa-east-1.amazonaws.com/assets/images/placeholder/credentech-sistemas%2Cpng.png"
              alt="credentech-logo"
            />
            <p className='z-10 px-24 mt-10 text-lg text-white text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo animi, officia placeat, eveniet quo repellat libero quasi voluptas ratione vitae vel voluptatem suscipit dolor beatae incidunt in! Fugit, ad natus.</p>
            <div className="absolute inset-0 bg-gradient-to-r from-[rgba(255,107,0,0.35)] to-[rgba(0,0,0,0.5)]"></div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Home;