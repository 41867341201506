import { motion } from "framer-motion";
import React from "react";
import { RiArrowRightFill } from "react-icons/ri";

type LayoutSelectionCardProps = {
  imgSrc: string;
  layoutName: string;
  layoutId?: string;
  delay: number;
}

function LayoutSelectionCard({delay, imgSrc, layoutName, layoutId}: LayoutSelectionCardProps) {
  return (
    <motion.div 
      initial={{ opacity: 0, scale: 1.2 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ delay: delay, duration: 0.5, ease: "easeOut" }}
      className="relative layoutSelectionCard"
    >
      <a
        className="block hover:scale-102 transform transition duration-300 relative"
        href={`/events/create/${layoutId}`}
      >
        <img 
          src={imgSrc} 
          alt="Layout demo"
          className="transition duration-300 ease-in-out transform hover:scale-105"
        />
        <div 
          className="absolute bottom-0 border inset-x-0 mx-auto w-full text-center py-2 rounded-full font-bold text-gray-500 bg-white shadow-xl flex justify-around items-center"
        >
          <span className="m-auto uppercase">{layoutName}</span>
          <RiArrowRightFill 
            className="inline-block pe-2" 
            style={{
              width: '30px',
              height: '30px'
            }}
          />
        </div>
      </a>
    </motion.div>
  );
}

export default LayoutSelectionCard;