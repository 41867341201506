import React from 'react'
import LayoutContainer from '../components/LayoutContainer';
import { useFetchDemoData } from '../hooks/useFetchDemoData';
import LayoutSection from '../components/LayoutSection';
import SectionHeader from '../components/SectionHeader';
import CategorySection from '../components/CategorySection';
import SponsorsContainer from '../components/SponsorsContainer';
import LayoutsHeaderGate from '../components/Header/LayoutsHeaderGate';
import filterParticipantsByCategory from '../utils/helpers/filterParticipantsByCategory';
import Loading from "./Loading";

function Guests() {
  const {data, loading} = useFetchDemoData();
  
  if (loading) {
    return (<Loading />);
  }

  const guestsCategory = data?.categories.find((guest) => guest.name === "Convidados Nacionais")

  return (
    <div className="Guests layout">
      <LayoutContainer>
        <LayoutsHeaderGate />
        <LayoutSection>
          <SectionHeader>CONVIDADOS</SectionHeader>
          <CategorySection
            category={guestsCategory!}
            participants={filterParticipantsByCategory(Object.values(data!.participants), guestsCategory!)}
          />
        </LayoutSection>
        <LayoutSection>
          <SponsorsContainer 
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
    </div>
  )
}

export default Guests;