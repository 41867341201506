import React from "react";
import { Tooltip } from 'react-tooltip'
import { CiImageOn } from 'react-icons/ci';
import { RiPencilFill } from "react-icons/ri";
import { HiDocumentSearch }  from 'react-icons/hi';

import TableHead from "./TableHead";
import TableCell from "./TableCell";

const events = [
  {
    logourl: '',
    nde: 'int. bh studios',
    name: 'Kotlin conference',
    creationdate: '12/08/2024',
    eventdate: '20/08/2024',
    manager: 'JAIME',
    status: 'active'
  }
]

function EventsDashboardTable() {
  return (
    <div className="eventsDashboardTable">
      <table className="table-auto min-w-full leading-normal">
        <thead>
          <tr className="text-center">
            <TableHead>Logo</TableHead>
            <TableHead>N.D.E</TableHead>
            <TableHead>Nome do Evento</TableHead>
            <TableHead>Data de Criação</TableHead>
            <TableHead>Data do Evento</TableHead>
            <TableHead>Ação</TableHead>
            <TableHead>Gestor</TableHead>
            <TableHead>Status</TableHead>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-gray-50 hover:bg-gray-100 transition-all">
            <TableCell>{events[0].logourl == '' ? <CiImageOn style={{height: '50px', width: '50px'}} /> : events[0].logourl}</TableCell>
            <TableCell>{events[0].nde}</TableCell>
            <TableCell>{events[0].name}</TableCell>
            <TableCell>{events[0].creationdate}</TableCell>
            <TableCell>{events[0].eventdate}</TableCell>
            <TableCell>
              <a 
                href=""
                className="inline-block me-2 transition-transform duration-200 ease-in-out hover:scale-110 outline-none"
                data-tooltip-id="edit-tooltip"
                data-tooltip-content="Editar"
                data-tooltip-place="bottom"
              >
                <RiPencilFill style={{height: '30px', width: '30px'}} />
                <Tooltip id="edit-tooltip" />
              </a>
              <a 
                href=""
                className="inline-block transition-transform duration-200 ease-in-out hover:scale-110 outline-none"
                data-tooltip-id="metrics-tooltip"
                data-tooltip-content="Métricas"
                data-tooltip-place="bottom"
              >
                <HiDocumentSearch style={{height: '30px', width: '30px'}} />
                <Tooltip id="metrics-tooltip" />
              </a>
            </TableCell>
            <TableCell>{events[0].manager}</TableCell>
            <TableCell>
              <div className="text-sm text-green-800 font-bold uppercase bg-green-400 px-4 py-1 rounded-full">
                {events[0].status}
              </div>
            </TableCell>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default EventsDashboardTable;