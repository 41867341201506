import React from "react";

interface ExpositorSectionProps extends React.HTMLAttributes<HTMLLIElement> {
  children?: React.ReactNode;
  title: string;
}

function ExpositorSection({title, children}: ExpositorSectionProps) {
  return (
    <div className="expositorSection">
      <div className='py-5 border-transparent border-b-neutral-300 border-2'>
        <p className="tracking-wider text-lg font-medium text-center">{title}</p>
        <div className="flex justify-center">
          {children}
        </div>
      </div>
    </div>
  );
}

export default ExpositorSection;