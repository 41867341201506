import React from "react";
import {ScheduleProgramming} from "../../../types/EventData";

interface ProgrammingContainerProps extends React.HTMLAttributes<HTMLLIElement> {
  children?: React.ReactNode;
  info: ScheduleProgramming;
  startDate: Date;
}

function ProgrammingContainer({info, startDate, children}: ProgrammingContainerProps) {
  const startTime = startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const endTime = new Date(startDate.getTime() + info.minutes * 60000);
  const endTimeFormatted = endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  console.log(info.minutes)

  return (
    <div className='flex'>
      <div className='px-4 py-4'>
        <p>{`${startTime} - ${endTimeFormatted}`}</p>
      </div>
      <div className='px-4 py-4 border-l border-gray-300 flex-grow'>
        <div className='bg-slate-200 h-full w-full flex flex-col gap-4 ps-4 py-2 rounded-lg'>
          {children}
        </div>
      </div>
    </div>
  );
}

export default ProgrammingContainer;