import axios from "axios";
import L from "leaflet";
import { Place } from "../types/EventData";

const NominatimBaseURL = 'https://nominatim.openstreetmap.org/search';

export type Coordinates = {
  lat: number;
  lon: number;
}
export type LocationName = string;

const customIcon = new L.Icon({
  iconUrl: 'http://localhost:3000/assets/images/placeholder/marker.png',
  iconSize: [35, 35], // Tamanho do ícone
  iconAnchor: [17, 45], // Ponto do ícone que corresponderá à localização do marcador
  popupAnchor: [1, -34], // Ponto onde o popup deve "abrir", relativo ao iconAnchor
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  shadowSize: [50, 64],
  shadowAnchor: [20, 62]
});

export async function getCoordinates({ street, number, city, state }: Place) {
  const address = `${number} ${street}, ${city}, ${state}, Brazil`;
  const url = `${NominatimBaseURL}?q=${encodeURIComponent(address)}&format=json&limit=1`;

  try {
    const response = await axios.get(url);
    
    if (response.data.length > 0) {
      const {lat, lon} = response.data[0];
      return {lat, lon};
    }
  } catch(error) {
    console.log("Erro ao obter geoloc: " + error);
  }
}

export function initMap({lat, lon}: Coordinates, name: LocationName) {
  const map = L.map('map');

  try {
    map.setView([lat, lon], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors'
    }).addTo(map);
  
    L.marker([lat, lon], {
      draggable: false,
      icon: customIcon
    })
      .addTo(map)
      .bindPopup(name)
      .openPopup();
    return map;
  } catch(e) {
    console.log(e)
  }
}