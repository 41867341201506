import React from "react";

import EventBanner from "../../components/Introduction/EventBanner";
import IntroductionSection from "../../components/Introduction/IntroductionSection";
import LayoutSection from "../../components/LayoutSection";
import Header from "../../components/Header";
import SpeakerCarousel from "../../components/Speakers/SpeakerCarousel";
import QuickLinks from "../../components/QuickLinks";
import SectionHeader from "../../components/SectionHeader";
import LocationDetails from "../../components/Location/LocationDetails";
import LocationAddress from "../../components/Location/LocationAddress";
import LocationMap from "../../components/Location/LocationMap";
import SponsorsContainer from "../../components/SponsorsContainer";
import LayoutContainer from "../../components/LayoutContainer";
import Navbar from "../../components/Header/Navbar";
import VideoPlayer from "../../utils/ui/VideoPlayer";
import { useFetchDemoData } from "../../hooks/useFetchDemoData";
import useWindowSize from "../../hooks/useWindowSize";
import Loading from "../Loading";

function Layout3() {
  const {data, loading} = useFetchDemoData();
  const { windowSize } = useWindowSize();

  if (loading) {
    return (<Loading />);
  }

  return (
    <div className="Layout3 layout">
        <LayoutContainer>
          <Header logo={data?.logourl!} />
          <div className="flex gap-12">
            <EventBanner 
              banner={data?.banner!}
              eventDate={data?.startdate!}
              place={data?.place!}
            />
            <Navbar  column logo={data?.logourl!} layout={data?.layout!} />
          </div>
          <LayoutSection>
            <IntroductionSection
              authorCenter
              title="Introducao"
              content={data!.introduction}
              author={data?.participants.find((participant) => participant.category?.name == "Presidente")!}
            />
          </LayoutSection>
          <LayoutSection>
            <QuickLinks horizontal />
          </LayoutSection>
          <LayoutSection>
            <SectionHeader>Palestrantes</SectionHeader>
            <SpeakerCarousel
              authors={data?.participants.filter((participant) => participant.isspeaker)!}
            />
          </LayoutSection>
          <LayoutSection>
            <SectionHeader>Localização</SectionHeader>
            <div className="flex">
              <div>
                <LocationMap
                  width={windowSize.width < 900 ? 300 : 450 * (windowSize.width * 0.0008)}
                  height={windowSize.width < 900 ? 320 : 400}
                  className="flex justify-center mb-12 mt-4"
                  location={data?.place!} 
                />
                <LocationAddress
                  street={data?.place.street!}
                  number={data?.place.number!}
                  district={data?.place.district!}
                  city={data?.place.city!}
                  state={data?.place.state!}
                  telephone={data?.place.telephone!}
                />
              </div>
              <div className="px-12">
                <VideoPlayer 
                  url="https://www.youtube.com/watch?v=NpLuHEA4plA" 
                  width={windowSize.width < 1200 ? 300 : 450}
                  height={windowSize.width < 900 ? 320 : 400}
                />
                <LocationDetails name={data?.place.name!} description={data?.place.description!}  />
              </div>
            </div>
          </LayoutSection>
          <LayoutSection>
            <SponsorsContainer 
              sponsorCategories={data?.sponsors!}
            />
          </LayoutSection>
        </LayoutContainer>
    </div>
  );
}

export default Layout3;