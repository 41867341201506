import React from "react";

interface LayoutSectionGroupProps extends React.HTMLAttributes<HTMLLIElement> {
  children?: React.ReactNode;
}

function LayoutSectionGroup({children}: LayoutSectionGroupProps) {
  return (
    <div className="layoutSectionGroup">
      <div className="border border-x-transparent border-t-transparent border-b-neutral-300 border-2 px-5 pt-4 pb-8">
        {children}
      </div>
    </div>
  );
}

export default LayoutSectionGroup;