import { motion } from "framer-motion";
import React from "react";
import EventsDashboardTable from "../components/EventsDashboardTable";

function EventsDashboard() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="eventsDashboard flex flex-col items-center"
    >
      <h1 className="font-bold text-3xl tracking-wide text-center py-6">Lista de Eventos</h1>
      <EventsDashboardTable />
    </motion.div>
  );
}

export default EventsDashboard;