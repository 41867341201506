import React from 'react'

import LayoutContainer from '../components/LayoutContainer';
import LayoutSection from '../components/LayoutSection';
import SectionHeader from '../components/SectionHeader';
import { useFetchDemoData } from '../hooks/useFetchDemoData';
import SponsorsContainer from '../components/SponsorsContainer';
import CategorySection from '../components/CategorySection';
import LayoutsHeaderGate from '../components/Header/LayoutsHeaderGate';
import Header from '../components/Header';
import filterParticipantsByCategory from '../utils/helpers/filterParticipantsByCategory';
import Loading from "./Loading";

function Comissions() {
  const {data, loading} = useFetchDemoData();
  
  if (loading) {
    return (<Loading />);
  }

  return (
    <div className="Comissions layout">
      <LayoutContainer>
        <LayoutsHeaderGate />
        <LayoutSection>
          <SectionHeader>COMISSÕES</SectionHeader>
          {data?.categories.map((value) => {
            const participants = filterParticipantsByCategory(Object.values(data!.participants), value)
            if (participants.length == 0 || value.name == "Convidados Nacionais") {
              return (<></>);
            }
            return (
              <CategorySection
                category={value}
                participants={participants}
              />
            );
          })}
        </LayoutSection>
        <LayoutSection>
          <SponsorsContainer 
            sponsorCategories={data?.sponsors!}
          />
        </LayoutSection>
      </LayoutContainer>
    </div>
  );
}

export default Comissions;