import React, {useEffect, useRef, useState} from "react";
import { CheckoutFormData } from "..";
import axios from "axios";
import io, {Socket} from "socket.io-client";
import Loading from "../../../pages/Loading";

type StepRender3Props = {
  formData: CheckoutFormData;
  onConfirm: (event: string) => void;
}

const socketUrl = 'https://api.credentech.com.br';

function StepRender3({onConfirm, formData}: StepRender3Props) {
  const [paymentLink, setPaymentLink] = useState('');
  const [isFrameLoaded, setFrameLoaded] = useState(false);
  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    socketRef.current = io(socketUrl, {});
    socketRef.current.on("connect", async () => {

      console.log("Connected socket with id: " + socketRef.current!.id);

      const telephoneMatch = formData.telephone.match(/\+\d{2} \((\d{2})\) (\d{5}-\d{4})/);
      
      if (!telephoneMatch) {
        console.log("Error on telephone: " + formData.telephone);
        return;
      }

      const areaCode = telephoneMatch[1];
      const number = telephoneMatch[2].replace('-', '');

      const response = await axios.post(`${socketUrl}/create_payment`, {
        category_id: formData.categoryid || 1,
        socket_id: socketRef.current!.id,
        subscriber: {
          id: 0,
          name: formData.firstname,
          surname: formData.surname,
          email: formData.email,
          cpf: formData.cpf,
          birthdate: formData.birthdate,
          state: formData.state,
          city: formData.city,
          district: formData.district,
          area_code: areaCode,
          number: number,
          address: formData.address,
          cep: formData.cep,
          complement: formData.complement,
          reference: formData.reference,
          invitecode: "",
          subscriptioncategoryid: formData.categoryid,
          street_number: formData.number,
          eventid: 13
        }
      });

      setPaymentLink(response.data);
      console.log(response)
    })

    socketRef.current.on("payment_status_update", (data: any) => {
      if (data.status == "pending") {
        return;
      }
      onConfirm(data.status);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  return (
    <div className="stepRender3 relative">
      {!isFrameLoaded ? (
        <div className="absolute inset-0 flex items-center justify-center bg-white z-10">
          <Loading />
        </div>
      ) : (<></>)}  
      <iframe 
        src={paymentLink}
        onLoad={() => setFrameLoaded(true)}
        className="w-full h-full relative"
        style={{
          minHeight: '630px'
        }}
      />
    </div>
  );
}

export default StepRender3;