import React from "react";

import {Category} from '../../types/EventData';
import {Participant} from '../../types/EventData';

type CategorySectionProps = {
  category: Category;
  participants: Participant[];
}

function CategorySection({category, participants}: CategorySectionProps) {
  return (
    <div className="ml-5 mb-5">
      <p className='tracking-widest text-sm uppercase font-medium'>{category.name}</p>
      <div className='flex'>
        {participants.map((participants) => (
          <div className='my-8 w-1/2 flex items-center'>
            <img className='profile-pic mb-4 max-w-20 max-h-20 mr-6' src={participants.profilepic} />
            <p className='font-semibold text-lg'>{participants.name}</p>
          </div>
        ))}
      </div>
    </div> 
  );
}

export default CategorySection;