import React from "react";
import { Participant } from "../../../types/EventData";

type PersonItemContainer = {
  person: Participant;
}

function PersonItemContainer({person}: PersonItemContainer) {
  return (
    <div className='flex items-center'>
      <img src={person.profilepic} className='profile-pic max-w-12 max-h-12 mr-6' />
      <p className='font-semibold text-lg'>{person.name}</p>
    </div> 
  );
}

export default PersonItemContainer;