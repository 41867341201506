import React from "react";
import {ScheduleDay} from "../../types/EventData";
import ListTitle from "./ListTitle";
import ProgrammingContainer from "./ProgrammingContainer";
import PersonItemContainer from "./PersonItemContainer";

type DayContainerProps = {
  day: ScheduleDay
}

function DayContainer({day}: DayContainerProps) {
  return (
    <div>
      <div className='flex flex-col'>
        {day.programming.map((programming) => (
          <div className="presentation">
            <ListTitle content={programming.place} />
            <ProgrammingContainer info={programming} startDate={day.date}>
              {programming.isinterval ? 
                (
                  <p className="ps-4 py-2 font-bold">Intervalo</p>
                ) : 
                (
                  <>
                    {programming.participants?.map((person) => (
                      <PersonItemContainer person={person} />
                    ))}
                  </>
                )
              }
            </ProgrammingContainer>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DayContainer;