import React from "react";

type ListTitleProps = {
  content: string;
}

function ListTitle({content}: ListTitleProps) {
  return (
    <div className='bg-blue-500 px-4 py-1 text-white font-semibold'>
      <p>{content}</p>
    </div>
  );
}

export default ListTitle;