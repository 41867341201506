import { motion } from "framer-motion";
import React, { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EventManagerProgress from "../components/EventManagerProgress";
import Info from "../components/EventManagerProgress/Steps/Info";

const stateComponents = [
  Info
];

function EventManager() {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { layoutName } = useParams();

  useEffect(() => {
    console.log(layoutName)
  }, []);

  const renderStep = (): ReactNode => {
    const StateComponent = stateComponents[currentStep];
    if (!StateComponent) {
      return <div>Invalid step</div>;
    }
    return <StateComponent />;
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="Login bg-white"
      style={{
        height: '100vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <nav className="flex justify-center items-center py-12 mb-8">
        <h1 className="font-bold text-4xl">Configure o Layout</h1>
      </nav>
      <div>
        <EventManagerProgress
          currentStep={currentStep}
          stepChange={(step) => setCurrentStep(step)}
        />
        <div>
          {renderStep()}
        </div>
      </div>
    </motion.div>
  );
}

export default EventManager;