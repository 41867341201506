import React from "react";
import NavItem from "./NavItem/NavItem";
import Header from "../../Header";
import { useParams } from "react-router-dom";

type NavbarProps = {
  column?: boolean;
  className?: string;
  logo?: string;
  noLogo?: boolean;
  layout: "layout1" | "layout2" | "layout3" | "layout4"
}

function Navbar({column, className, logo, layout, noLogo}: NavbarProps) {
  const { eventSlug } = useParams();
  
  return (
    <nav className="navbar">
      {layout === "layout2" && !noLogo ? <Header small logo={logo!}  /> : <></>}
      <div className={`h-full ${className}`}>
        <ul className={`list-none flex gap-2 w-full  ${column ? 'flex-col' : ''}`}>
          <NavItem className={column ? "px-28" : ""} redirect={`/${eventSlug}`}>Home</NavItem>
          <NavItem column={column} hasDropdown>
            Sobre
            <NavItem redirect={`/${eventSlug}/comissions`}  className="mt-1">Comissões</NavItem>
            <NavItem redirect={`/${eventSlug}/guests`} className="mt-1">Convidados</NavItem>
            <NavItem redirect={`/${eventSlug}/expositors`} className="mt-1">Expositores</NavItem>
          </NavItem>
          <NavItem redirect={`/${eventSlug}/subscriptions`}>Inscrição</NavItem>
          <NavItem redirect={`/${eventSlug}/schedule`}>Programação</NavItem>
          <NavItem redirect={`/${eventSlug}/support`}>Fale Conosco</NavItem>
        </ul>
      </div>
      {layout === "layout4" && !noLogo ? <Header center logo={logo!}  /> : <></>}
      {layout === "layout1" && !noLogo ? <Header logo={logo!}  /> : <></>}
    </nav>
  );
}

export default Navbar;