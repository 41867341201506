import React from "react";
import StepCircle from "./StepCircle";

type StepProgressProps = {
  step: number;
}

function StepProgress({step}: StepProgressProps) {
  const stepCirclesTitles = [
    "INFORMAÇÕES",
    "CONFIRMAR INFORMAÇÕES",
    "PAGAMENTO",
    "CONFIRMAÇÃO"
  ]

  return (
    <div className="stepProgress">
      <div className="flex justify-between flex-col gap-8 md:flex-row md:gap-0">
        {stepCirclesTitles.map((stepCircleTitle, index) => (
          <StepCircle index={index + 1} title={stepCircleTitle} fill={(index + 1) > step} />
        ))}
      </div>
    </div>
  );
}

export default StepProgress;